import React, { useEffect, useState } from "react";
import postscribe from "postscribe";
import { useLocation } from "@reach/router";
import SimpleLanding from "../../../layouts/simple-landing";
import SEO from "../../../components/seo/seo";

const HomeEquityWorkflow = () => {
  const SEOData = {
    title: "Home Equity Application",
    meta: [
      {
        name: "title",
        content: "Home Equity Application"
      },
      {
        name: "robots",
        content: "noindex, nofollow"
      }
    ]
  };

  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const [lar, setLar] = useState("");

  useEffect(() => {
    const larValue = searchParams.get("lar");
    if (larValue) {
      setLar(larValue);
    }
  }, [searchParams]);

  useEffect(() => {
    if (lar) {
      const config = {
        workFlowId: "192709",
        lar,
        externalLoginWindow: "true"
      };
      postscribe(
        "#home-equity-workflow",
        `<script src="https://widget.ellieservices.com/latest/launcher.js"></script>`
      );
      const widgetConfig = JSON.stringify(config);
      document.querySelector(".em-widget").setAttribute("data-config", widgetConfig);
    }
  }, [lar]);

  return (
    <SimpleLanding>
      <SEO {...SEOData} />
      <div id="home-equity-workflow"></div>
      <div
        class="em-widget"
        data-width="100%"
        data-height="1200px"
        data-min-height="800px"
        data-site-id="1233063672"
        data-name="ecc-loan-app"
        data-aria-label="My Loan Application"
      ></div>
    </SimpleLanding>
  );
};

export default HomeEquityWorkflow;
