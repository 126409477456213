import React from "react";
import { useLanguageContext } from "../../contexts/language-context";

// components
import MobileFooter from "./footer-mobile";
import FooterBottom from "./footer-bottom";

// Data
import { socialLinksSpanish } from "../../data/footer-data-spanish";
import { socialLinksEnglish } from "../../data/footer-data";

const SimpleFooter = () => {
  const isSpanish = useLanguageContext();
  const socialLinks = isSpanish ? socialLinksSpanish : socialLinksEnglish;

  return (
    <footer id="footer-simple" className="border-top">
      <FooterBottom />

      {/* end #footer-bottom */}
      <MobileFooter isSpanish={isSpanish} socialLinks={socialLinks} />
    </footer>
  );
};

export default SimpleFooter;
