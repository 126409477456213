import React from "react";
import PropTypes from "prop-types";

// Components
import GlobalLayout from "./global-layout";
import SimpleHeader from "../components/header/simple-header";
import SimpleFooter from "../components/footer/simple-footer";

const SimpleLanding = ({ children }) => {
  return (
    <GlobalLayout>
      <SimpleHeader />
      <main id="main-content">{children}</main>
      <SimpleFooter />
    </GlobalLayout>
  );
};

SimpleLanding.propTypes = {
  children: PropTypes.node.isRequired
};

export default SimpleLanding;
