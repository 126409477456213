import React from "react";
import { Link } from "gatsby";

// Styles
import styles from "./header.module.scss";

// Images
import Logo from "../../assets/wafd-bank-logo.svg";

const SimpleHeader = () => {
  return (
    <header id="primary-nav-header" className="sticky-top bg-white shadow-sm">
      <div className="container">
        {/* LOGO */}
        <div id="header-logo-container">
          <Link to={"/"} className="navbar-brand py-2 mr-3">
            <img
              id="header-wafd-bank-logo"
              src={Logo}
              className={`img-responsive ${styles.brandLogo}`}
              alt="WaFd Bank logo"
            />
          </Link>
        </div>
      </div>
    </header>
  );
};

export default SimpleHeader;
